<template>
  <div>
    <b-card>
      <public-filter
        :action-methods="searchData"
      />
    </b-card>
    <b-card>
      <b-table
        striped
        hover
        responsive
        :fields="fields"
        :items="dataList"
      >
        <template #cell(customer)="data">
          <div>{{ data.item.customer }}</div>
        </template>
        <template #cell(brand)="data">
          {{ data.item.brand }} {{ data.item.model }}
        </template>
        <template #cell(control)="data">
          <b-button
            variant="primary"
            size="sm"
            :to="'/tirehotel/add/' + data.item.cars_id"
            class="mr-1"
          >
            Seç
          </b-button>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardFooter, BPagination, BTable, BButton,
} from 'bootstrap-vue'
import PublicFilter from '@/views/Spareparts/TireHotel/Filter/PublicFilter.vue'

export default {
  name: 'Add',
  components: {
    BTable,
    BButton,
    BPagination,
    BCard,
    BCardFooter,
    PublicFilter,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: 'customer',
          label: 'Adı Soyadı',
        },
        {
          key: 'brand',
          label: 'Marka / Model',
        },
        {
          key: 'license_plate',
          label: 'Plaka',
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '260px', textAlign: 'left', verticalAlign: 'middle' },
        },
      ],
      dataQuery: {
        select: [
          'com_customer.id as customer_id',
          'com_cars.id as cars_id',
          'com_customer.name as customer',
          'com_brand.name as brand',
          'com_model.title as model',
          'com_cars.license_plate as license_plate',
        ],
        order_by: ['com_cars.id', 'desc'],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['cars/getCars']
    },
    dataCount() {
      return this.$store.getters['cars/getCarsCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$store.dispatch('cars/carsList', this.dataQuery)
    },
    searchData() {
      if (this.quickSearch.keyword.length > 0) {
        this.dataQuery.or_like = {
          'com_customer.name': this.quickSearch.keyword,
          'com_customer.company_name': this.quickSearch.keyword,
        }
        this.getDataList()
      }
      if (this.quickSearch.license_plate.length > 0) {
        this.dataQuery.or_like = {
          'com_cars.license_plate': this.quickSearch.license_plate,
        }
        this.getDataList()
      }
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
  },
}
</script>
