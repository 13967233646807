<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          xs="12"
          md="5"
        >
          <b-form-group
            label="Müşteri veya Şirket Adı"
            label-for="customer"
          >
            <b-form-input
              id="customer"
              v-model="quickSearch.keyword"
              placeholder="Müşteri veya Şirket Adı"
            />
          </b-form-group>
        </b-col>
        <b-col
          xs="12"
          md="5"
        >
          <b-form-group
            label="Plaka"
            label-for="license_plate"
          >
            <b-form-input
              id="license_plate"
              v-model="quickSearch.license_plate"
              placeholder="Plaka"
            />
          </b-form-group>
        </b-col>
        <b-col class="d-flex align-items-center">
          <b-button
            variant="primary"
            @click="searchAction"
          >
            <FeatherIcon icon="FilterIcon" />
            Filtrele
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'

export default {
  name: 'PublicFilter',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    actionMethods: {
      type: Function,
    },
  },
  computed: {
    quickSearch() {
      return this.$store.getters['tireHotel/getQuickSearch']
    },
  },
  methods: {
    filterAction() {
      this.actionMethods(this.filterData)
    },
    searchAction() {
      this.actionMethods()
    },
  },
}
</script>
<style lang="scss">

@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
